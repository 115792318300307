import React from 'react'
import Head from 'next/head'
import Error from 'next/error'

import AppBar from 'Components/AppBar'
import { SVGError500Img, SVGIllustration404 } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from 'Scss/pages/_error.scss'
import appBar from 'Components/AppBar/AppBar.scss'
import { NextContext } from 'next'
import PageLink from 'Components/PageLink';
import Footer from 'Components/Footer';

type PropType = {
  statusCode: number
  additionalErrorMessage?: string
}

export default class ErrorPage extends React.Component<PropType> {
  static getInitialProps({ res, err, req }: NextContext & { err: { statusCode: number } }) {
    const statusCode = res ? res.statusCode : err ? err.statusCode : null
    return { statusCode }
  }

  render404 = () => {
    return (
      <div className={cn(cl.errorPage)}>
        <Head>
          <title>404Page</title>
        </Head>
        <div className={cn(cg.contentHolder)}>
          <div className={cn(cg.gridContainer, cg.panelLayout, cg.opened)}>
            <div className={cn(cg.contentPanel, appBar.contentPanel, cg.opened)}>
              <div className={cn(cg.gridX, cg.gridMarginX, cg.alignMiddle)}>
                <div className={cn(cg.cell, cg.large7, cl.text)}>
                  <h1 className={cl.h1}>
                    <em>404</em>

                    <span className={cl.fontHandwriting}>
                      Valami hiányzik.
                    </span>
                  </h1>

                  <p>
                    Az oldal nem létezik, vagy már nem aktuális. <br />
                    Töltsd meg az oldalt és a hűtőd tartalommal, <br />
                    használd a keresőt, vagy látogass el a <PageLink toPage="/">nyitólapra!</PageLink>
                  </p>
                </div>

                <div className={cn(cg.cell, cg.large5)}>
                  <div className={cn(cl.illustrationWrapper, cl.illustrationWrapper__404)}>
                    <SVGIllustration404 width={398} height={347} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render500 = () => {
    return (
      <div className={cn(cl.errorPage)}>
        <Head>
          <title>500Page</title>
        </Head>
        <div className={cn(cg.contentHolder)}>
          <div className={cn(cg.gridContainer, cg.panelLayout, cg.opened)}>
            <div className={cn(cg.contentPanel, appBar.contentPanel, cg.opened)}>
              <div className={cn(cg.gridX, cg.gridMarginX, cg.alignMiddle)}>
                <div className={cn(cg.cell, cg.large7, cl.text)}>
                  <h1 className={cl.h1}>
                    <em>500</em>

                    <span className={cl.fontHandwriting}>
                      Valami nem stimmel.
                    </span>
                  </h1>

                  {this.props.additionalErrorMessage ?
                    <p>
                      {this.props.additionalErrorMessage}
                    </p>
                    :
                    <p>
                      Technikai problémába ütköztünk.<br />
                      Dolgozunk a megoldáson. Türelmedet kérjük.<br />
                      Addig használd a keresőt, vagy látogass el a <PageLink toPage="/">nyitólapra!</PageLink>
                    </p>
                  }
                </div>

                <div className={cn(cg.cell, cg.large5)}>
                  <div className={cn(cl.illustrationWrapper, cl.illustrationWrapper__500)}>
                    <SVGError500Img width={373} height={350} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderErrorPage = (statusCode: number) => {
    switch (statusCode) {
      case 404:
        return this.render404()
        break
      case 500:
        return this.render500()
        break
      default:
        return <Error statusCode={statusCode} />
    }
  }

  render() {
    const { statusCode } = this.props

    return (
      <div>
        <AppBar
          pageType="Home"
          breadcrumb="Error"
        />

        {
          statusCode
            ? this.renderErrorPage(statusCode)
            : this.renderErrorPage(500)
        }
        <Footer />
      </div>
    )
  }
}
